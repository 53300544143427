import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';

import types from '../mutation-types';
import SwitchesAIsAPI from '../../api/switchingAIs';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  uiFlags: {
    isFetchingAIs: false,
    isUpdatingSwitcher: false,
    isDeleting: false,
    isCreating: false,
  },
};

export const getters = {
  getSwitchers($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }, { account_id }) => {
    commit(types.SET_SWITCHING_UI_FLAG, { isFetchingAIs: true });
    try {
      const response = await SwitchesAIsAPI.getSwitchesAIs(account_id);
      commit(types.SET_SWITCHING_DATA, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SWITCHING_UI_FLAG, { isFetchingAIs: false });
    }
  },
  create: async ({ commit }, { payload, account_id }) => {
    commit(types.SET_SWITCHING_UI_FLAG, { isCreating: true });
    try {
      const response = await SwitchesAIsAPI.setKbToRbSwitches(
        payload,
        account_id
      );
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_SWITCHING_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  delete: async ({ commit }, { switch_id, account_id }) => {
    commit(types.SET_SWITCHING_UI_FLAG, { isDeleting: true });
    try {
      await SwitchesAIsAPI.deleteSwitchesAI(switch_id, account_id);
      commit(types.DELETE_SWITCHING_AI_ITEM, switch_id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_SWITCHING_UI_FLAG, { isDeleting: false });
    }
  },
  update: async ({ commit }, { payload, account_id }) => {
    commit(types.SET_SWITCHING_UI_FLAG, { isUpdatingSwitcher: true });
    try {
      await SwitchesAIsAPI.updateSwitcher(payload, account_id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_SWITCHING_UI_FLAG, { isUpdatingSwitcher: false });
    }
  },

  updateCondition: async (
    { commit },
    { condition, account_id, condition_id }
  ) => {
    commit(types.SET_SWITCHING_UI_FLAG, { isUpdatingSwitcher: true });
    try {
      await SwitchesAIsAPI.updateCondition(condition, account_id, condition_id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_SWITCHING_UI_FLAG, { isUpdatingSwitcher: false });
    }
  },
};

export const mutations = {
  // eslint-disable-next-line no-shadow
  [types.SET_SWITCHING_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  // eslint-disable-next-line no-shadow
  [types.SET_SWITCHING_DATA]($state, data) {
    $state.records = data;
  },
  [types.DELETE_SWITCHING_AI_ITEM]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
