import { render, staticRenderFns } from "./ContactDropdownItem.vue?vue&type=template&id=d4868daa&scoped=true&"
import script from "./ContactDropdownItem.vue?vue&type=script&lang=js&"
export * from "./ContactDropdownItem.vue?vue&type=script&lang=js&"
import style0 from "./ContactDropdownItem.vue?vue&type=style&index=0&id=d4868daa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4868daa",
  null
  
)

export default component.exports